// colors

$ya-color-red: #c53931;
$ya-color-green: #49be4a;
$ya-color-yellow: #bdc030;
$ya-color-orange: #d26b31;
$ya-color-dark: #2e2e2e;
$ya-color-highlight: #ec6560;
$ya-color-footer: #a9a9a9;
$ya-color-card-title: #5e5e5e;
$ya-color-sensitivity-public: #00882f;
$ya-color-sensitivity-private: #ffda0d;
$ya-color-sensitivity-secret: #ff5050;
$ya-color-grey: #5f5f5f;
$ya-light-red: #f91919;
$ya-light-yellow: #fbc400;
$ya-light-green: #1a9447;
$ya-light-black: #333333;
$ya-light-grey: #6b6b6b;
$ya-darker-grey: #3e3e3e;
$ya-greyish: #a2a2a2;
$ya-informative-red: #dc4c44;
$ya-informative-blue: #1976d2;
$ya-supportive-blue: #0684f8;
$ya-supportive-dark-blue: #1c548b;
$ya-neutral-light: #dedede;
$ya-neutral-grey: #797979;
$ya-neutral-light-gray: #bdbdbd;
$ya-dark-grey: #a9a9a9;
$ya-primary-medium: #5e5e5e;
$ya-background-green: #f1f8ee;

$ya-sender-color: #f0f0f0;
$ya-receiver-color: #5e94c9;

// Chat
$ya-message-from-color: #404040;
$ya-user-message-color: #fff;
$ya-user-message-background-color: #5e94c9;
$ya-message-color: #34303e;
$ya-message-background-color: #f0f0f0;
$ya-message-timestamp-color: #a9a9a9;
$ya-user-message-timestamp-color: #b2d0ee;

$ya-message-reply-from-color: #a3d1ff;
$ya-message-reply-from-user-color: #5e5e5e;

$ya-favorited-color: #fbc400;

// Center Stats
$ya-center-status-active: #78d04f;
$ya-center-status-inactive: #f91919;

// text
$ya-main-font-size: 16px;

// button
$ya-button-height: 48px;
$ya-button-font-size: 14px;
$ya-button-light-height: 45px;
$ya-button-normal-height: 30px;

// layout

$ya-default-padding: 16px;
$ya-card-default-padding: 10px;
$ya-card-border-radius: 11px;
$ya-border-radius: 14px;
$ya-border-radius-light: 12px;
$ya-border-width: 2px;
$ya-border-color: #dedede;
$ya-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08);
$ya-default-background: #fff;

$ya-form-border-color: #5e5e5e;
$ya-form-border-radius: 8px;

$ya-context-menu-border-radius: 9px;

$ya-menu-width: 420px;
$ya-window-width: 536px;

//logo color layer
$ya-main-blue: #2e2e2e;
$ya-dark-red: #d80000;

// z-index

$ya-context-menu: 200;
