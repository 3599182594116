@use '@angular/material' as mat;
@import '@fontsource/nunito/400.css';
@import '@fontsource/nunito/700.css';
@import 'theme';
@import 'variables';

@include mat.all-component-themes($ya-theme);

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  margin: 0;
}

*,
.mat-typography {
  font-family: $ya-font-family !important;
}

/* ya specific override */

.cdk-overlay-container {
  position: inherit;

  .cdk-overlay-connected-position-bounding-box {
    z-index: 5000;

    &:has(.dynamic-menu):not(:has(ya-settings-menu)) {
      z-index: 100;
    }
  }
}

.ya-menu-overlay {
  border-radius: 18px !important;
  width: auto !important;
  height: auto !important;
  max-width: initial !important;
  max-height: initial !important;
}


.ya-dynamic-menu-overlay {
  pointer-events: none;

  .mat-mdc-menu-content {
    padding: 0;

    &>* {
      pointer-events: auto;
      cursor: default !important;
      margin: $ya-default-padding;
      width: inherit;
    }
  }

  .show-button-horizontal {
    top: 3.5rem;

    &.right {
      right: 4rem;
    }
  }

  .show-button-right {
    right: 4rem;
  }

  .show-button-left {
    left: 4rem;
  }
}

.receiver-list-menu {
  border-radius: 8px !important;
  box-shadow: $ya-shadow !important;
  max-height: 10rem !important;

  &::-webkit-scrollbar {
    width: 1em;
  }

  &::-webkit-scrollbar-thumb {
    border: 0.3em solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 1em;
    background-color: $ya-border-color;
  }

  .mat-mdc-menu-item {
    font-size: 12px !important;
    min-height: 20px !important;
    height: 20px !important;
    margin: 0.2rem 0 0 0;
    display: inline-block;

    .checkmark {
      display: none;
    }

    &.selected {
      background-color: $ya-color-dark;

      .checkmark {
        display: inline;
        margin-left: 0.3rem;
      }

      span {
        color: white;
      }
    }
  }
}

.material-icons {
  font-family: 'Material Icons', Helvetica, sans-serif !important;
}

.ya-msa-selected-layers-overlay .layerOpt {
  display: flex;
  gap: 0.3rem;
}

.ya-msa-smart-rule-overlay {
  min-width: fit-content;
}

.maplibregl-popup-content {
  min-width: 300px;
  width: fit-content;
  .marker-popup {
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: -25px;

    ul {
      display: flex;

      li {
        padding-left: 1rem;

        &:first-child {
          padding-left: 0;
        }
      }
    }

    .header {
      width: 95%;
    }

    .footer {
      display: flex;
      justify-content: flex-end;

      button {
        min-width: fit-content;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 10px;
        color: $ya-neutral-light;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 0.5rem;

        &:last-child {
          margin: 0;
        }
      }

      .main-button {
        background-color: $ya-informative-blue;
      }

      .remove-button {
        background-color: $ya-color-red;
      }
    }
  }
}


/* Storybook */
#root {
  height: 100%;
  overflow-y: auto;
}
